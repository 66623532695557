import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../../utility/utils"

const CookieMobile = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <div className="kuda-inner--heading kuda-disclaimer--section">
        <div className="kuda-section--inner general-style">
          <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
            <Link to="/mobile/ng/legal/" className="color-black mb-3 mt-4 back-page">
              <div className="align-self-center mr-2">
                <div className="f-14">
                  Legal
                </div>
              </div>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 mr-2 text-left">Nigeria</span>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 text-bold text-left">Cookie</span>
            </Link>
            <div className="legal-left">
              <h1 className="kuda-section--heading text-lg-biz mb-0 text-xlbold color-black title-bottom--spacing no-margins">
                Cookie Policy
              </h1>
            </div>
          </div>

    
              <div className="kuda-section--100 kuda-spacing--inner">
                <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
                  What Are Cookies Anyway?
                </h1>
                <div className="text-left kuda-disclaimer--wrap">
                  <p className="color-black bottom--spacing">
                    kuda.com uses cookies, which are small pieces of data saved on
                    your phone or computer, to improve your browsing experience.
                  </p>
                  <p className="color-black bottom--spacing">
                    Please, keep reading to learn what information we collect with
                    cookies, how we use that information, why we sometimes store it
                    and how you can disable cookies on your browser.
                  </p>
                </div>
              </div>

              <div className="kuda-section--100 kuda-spacing--inner">
                <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
                  Cookies We Set
                </h1>
                <h4 className="card-main--heading color-black text-semi-bold mb-3">
                  When You Sign In
                </h4>
                <div className="text-left kuda-disclaimer--wrap">
                  <p className="color-black bottom--spacing">
                    We use cookies to save your login information so you don't have
                    to sign in all over again every time you go to a new page.
                  </p>
                  <p className="color-black bottom--spacing">
                    We typically clear these cookies when you sign out to prevent
                    access to features that require signing in.
                  </p>

                  <h4 className="card-main--heading color-black text-semi-bold mb-3">
                    Email Newsletters
                  </h4>
                  <div className="text-left kuda-disclaimer--wrap">
                    <p className="color-black bottom--spacing">
                      We use cookies to save information you give us when you
                      subscribe to our emails. This helps us avoid duplicate
                      subscriptions and controls the notifications you see.
                    </p>
                  </div>

                  <h4 className="card-main--heading color-black text-semi-bold mb-3">
                    Forms
                  </h4>
                  <div className="text-left kuda-disclaimer--wrap">
                    <p className="color-black bottom--spacing">
                      We set cookies to remember your user information when you fill
                      a form on the website.
                    </p>
                  </div>

                  <h4 className="card-main--heading color-black text-semi-bold mb-3">
                    Third-Party Cookies
                  </h4>
                  <div className="text-left kuda-disclaimer--wrap">
                    <p className="color-black bottom--spacing">
                      Sometimes, we set cookies from trusted third parties.
                    </p>
                    <p className="color-black bottom--spacing">
                      Please, keep reading to learn about third-party cookies on
                      kuda.com.
                    </p>
                  </div>

                  <h4 className="card-main--heading color-black text-semi-bold mb-3">
                    Google Analytics
                  </h4>
                  <div className="text-left kuda-disclaimer--wrap">
                    <p className="color-black bottom--spacing">
                      We use cookies from Google Analytics to help us understand how
                      you use this website and how we can improve your experience.
                      These cookies collect information including how much time you
                      spend on this website and the pages you visit.
                    </p>
                  </div>

                  <h4 className="card-main--heading color-black text-semi-bold mb-3">
                    For Testing
                  </h4>
                  <div className="text-left kuda-disclaimer--wrap">
                    <p className="color-black bottom--spacing">
                      We often make changes to this website and add new features,
                      and while we're texting them, we may use cookies to learn the
                      optimisations you prefer.
                    </p>
                  </div>
                </div>

                <div className="kuda-section--100 kuda-spacing--inner">
                  <h1 className="kuda-section--heading text-left text-xlbold color-black title-bottom--spacing">
                    Disabling Cookies
                  </h1>

                  <div className="text-left kuda-disclaimer--wrap">
                    <p className="color-black bottom--spacing">
                      To disable cookies, please check your browser settings.
                      Please, note that disabling all cookies may affect your
                      experience on this website.
                    </p>
                    <p className="color-black bottom--spacing">
                      We hope we've clarified how we use cookies to your
                      satisfaction and you feel comfortable using this website. If
                      you need more information, please send a message to
                      hello@kuda.com and we'll get back to you as soon as possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        
      </div>
    </Fragment>
  )
}

export default CookieMobile
